<template>
  <el-dialog
    :visible.sync="migratingDialog"
    width="640px"
    :custom-class="'migrating_dialog'"
    :append-to-body="true"
    :modal-append-to-body="true"
    :top="'0'"
    :before-close="close"
  >
    <div
      class="migrating_content"
      v-loading="submitLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,250,250,0.1)"
    >
      <span class="close_icon icon-x iconfont_Me" @click.stop="close"></span>
      <div class="migrating_title">
        {{ $t("migrating_dialog_tips") }}
      </div>
      <div class="migrating_input">
        <el-form @submit.stop="() => false">
          <div class="input">
            <el-form-item prop="userName">
              <CommonInput
                @inputChange="getEmailText"
                :needInputshowError="emailError"
                :defaultInputText="migratingDialogAddress"
                :readOnly="true"
              >
                <el-image
                  :src="
                    require('../../../../../../assets/images/email/loginDialog/email_not_selected.png')
                  "
                  :fit="'cover'"
                  :style="{
                    width: '24px',
                    height: '24px',
                  }"
                ></el-image>
              </CommonInput>
            </el-form-item>
          </div>
          <div class="input">
            <el-form-item prop="emailPassword">
              <CommonInput
                @inputChange="getPasswordText"
                :passwordInput="true"
                :placeholderText="$t('migrate_input_tips_1')"
                :needInputshowError="passwordError"
              >
                <span class="iconfont_Me icon-password-fill iconStyle"></span>
              </CommonInput>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div
        class="migrating_button"
        v-waves
        @click.stop="startMigrate"
        :style="{
          cursor: disabled ? 'not-allowed' : 'pointer',
          background: disabled ? '#D9D9D9' : '#52BD68',
        }"
      >
        {{ $t("startMailboxMigrate") }}
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CommonInput from "../../../../../../newVersion/components/inputComponent/commonInput.vue";
import isEmail from "validator/lib/isEmail";
import { transferMail } from "@/api/newVersion/team";
import { encryptData } from "@/utils/aes_email";
export default {
  name: "MigratingDialog",
  components: {
    CommonInput,
  },
  data() {
    return {
      emailError: false,
      passwordError: false,
      emailText: "",
      emailPassword: "",
      submitLoading: false,
    };
  },
  computed: {
    disabled() {
      return (
        this.globalTrim(this.emailText) == "" ||
        this.globalTrim(this.emailPassword) == ""
      );
    },
    migratingDialog() {
      return this.$store.state.melinked_email.migratingDialog;
    },
    migratingDialogAddress() {
      return this.$store.state.melinked_email.migratingDialogAddress;
    },
  },
  mounted() {
    if (!!this.migratingDialogAddress) {
      this.emailText = this.migratingDialogAddress;
    }
  },
  methods: {
    close() {
      this.$store.commit("setMigratingDialog", false);
    },
    getEmailText(email) {
      this.emailText = email;
      this.emailError = false;
    },
    getPasswordText(password) {
      this.emailPassword = password;
    },
    async startMigrate() {
      if (this.disabled) {
        return;
      }
      if (this.submitLoading) {
        return;
      }
      this.submitLoading = true;
      if (isEmail(this.emailText)) {
        const createdToken = {
          u: this.emailText,
          p: this.emailPassword,
        };
        const realTime = await this.getRealTime();
        createdToken.u = createdToken.u.toLowerCase();
        createdToken.t = realTime;
        const result = await transferMail({
          mailToken: encryptData(JSON.stringify(createdToken)),
        });
        this.submitLoading = false;
        if (result.code == 200) {
          this.$message({
            type: "success",
            message: this.$t("migrate_tips_1"),
          });
          this.close();
        } else if (result.code == 178134) {
          this.$message({
            type: "error",
            message: this.$t("migrate_tips_2"),
          });
        } else if (result.code == 178131 || result.code == 502) {
          this.$message({
            type: "error",
            message: this.$t("migrate_tips_3"),
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } else {
        this.emailError = true;
        this.$message({
          type: "error",
          message: this.$t("emailAddressIsIncorrect"),
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
:deep .migrating_dialog
  width: 100%;
  height: 500px;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    width: 100%;
    height: 100%;
    .migrating_content
      width: 100%;
      height: 100%;
      position: relative;
      padding-top: 1px;
      .close_icon
        font-size: 16px;
        color: #858585;
        position: absolute;
        top: 24px;
        right: 20px;
        cursor: pointer;
      .migrating_title
        font-size: 20px;
        max-width: 430px;
        margin: 89px auto 0 auto;
        color: #292D32;
        text-align: center;
      .migrating_input
        width: 480px;
        height: 120px;
        margin: 50px auto 0 auto;
        .el-form
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          .el-form-item
            height: 100%;
            width: 100%;
            .el-form-item__content
              height: 100%;
              width: 100%;
        .input
          width: 100%;
          height: 48px;
          .iconStyle
            color #666666;
            font-size 28px;
      .migrating_button
        width: 480px;
        height: 48px;
        border-radius: 4px;
        user-select: none;
        margin: 60px auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 18px;
</style>
