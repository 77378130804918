var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.migratingDialog,
        width: "640px",
        "custom-class": "migrating_dialog",
        "append-to-body": true,
        "modal-append-to-body": true,
        top: "0",
        "before-close": _vm.close
      },
      on: {
        "update:visible": function($event) {
          _vm.migratingDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.submitLoading,
              expression: "submitLoading"
            }
          ],
          staticClass: "migrating_content",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,250,250,0.1)"
          }
        },
        [
          _c("span", {
            staticClass: "close_icon icon-x iconfont_Me",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.close.apply(null, arguments)
              }
            }
          }),
          _c("div", { staticClass: "migrating_title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("migrating_dialog_tips")) + "\n    "
            )
          ]),
          _c(
            "div",
            { staticClass: "migrating_input" },
            [
              _c(
                "el-form",
                {
                  on: {
                    submit: function($event) {
                      $event.stopPropagation()
                      return (() => false).apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "userName" } },
                        [
                          _c(
                            "CommonInput",
                            {
                              attrs: {
                                needInputshowError: _vm.emailError,
                                defaultInputText: _vm.migratingDialogAddress,
                                readOnly: true
                              },
                              on: { inputChange: _vm.getEmailText }
                            },
                            [
                              _c("el-image", {
                                style: {
                                  width: "24px",
                                  height: "24px"
                                },
                                attrs: {
                                  src: require("../../../../../../assets/images/email/loginDialog/email_not_selected.png"),
                                  fit: "cover"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "emailPassword" } },
                        [
                          _c(
                            "CommonInput",
                            {
                              attrs: {
                                passwordInput: true,
                                placeholderText: _vm.$t("migrate_input_tips_1"),
                                needInputshowError: _vm.passwordError
                              },
                              on: { inputChange: _vm.getPasswordText }
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "iconfont_Me icon-password-fill iconStyle"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "migrating_button",
              style: {
                cursor: _vm.disabled ? "not-allowed" : "pointer",
                background: _vm.disabled ? "#D9D9D9" : "#52BD68"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startMigrate.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("startMailboxMigrate")) + "\n    "
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }